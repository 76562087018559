import React, {useState,useEffect} from 'react';
import workoutPlanService from '../services/workoutPlans.js';
import '../styles/home.css';
import {Link} from "react-router-dom";
const Home = (props) => {
  //VERY  IMPORTANT!!!! IN THE LINK IT IS NOT AN APOSTROPHE!! IT IS A TILDE
  const [allWorkoutPlans,setAllWorkoutPlans] = useState([])
  const [uniqueWorkoutDays, setUniqueWorkoutDays] = useState([])
  const [uniqueWorkoutPlans, setUniqueWorkoutPlans] = useState([])

  useEffect(() => {
    //Gets all workout plans
    workoutPlanService
    .getAll()
    .then(response => {
      console.log('promise fulfilled')
      //console.log('these are the workout plans',response)
      //console.log('this is the user',props.user)
      setAllWorkoutPlans(response.filter(plan => plan.user === props.user.username))
      console.log('home page use effect')
      // Finds the unique workout days for the default workout
      const flags={}
      const tempUniqueWorkoutDays =  response.filter(plan => plan.workoutPlan === props.user.workoutPlan).filter(function(entry){
        if (flags[entry.workoutDay]){
          return false
        }
        flags[entry.workoutDay] = true
        return true
      })
      tempUniqueWorkoutDays.sort((a,b) => {
        return a.workoutDayOrder-b.workoutDayOrder;
      })
      setUniqueWorkoutDays(tempUniqueWorkoutDays)
      //Finds the unique workoutplans for user
      const flags2={}
      const tempUniqueWorkoutPlans =  response.filter(plan => plan.user === props.user.username).filter(function(entry){
        if (flags2[entry.workoutPlan]){
          return false
        }
        flags2[entry.workoutPlan] = true
        return true
      })
      setUniqueWorkoutPlans(tempUniqueWorkoutPlans)
    })
  }, [])

  //state for dropdown menu to be open or closed
  const [open, setOpen]  = useState(false);
  function DropdownMenu(){
    return(
      <div className='wp-dropdown'>
        <ul  className = 'wp-dropdown-list'>
        {uniqueWorkoutPlans.map(wp=>
        <a href="#"  className = 'wp-dropdown-item'  onClick={()=>changeWorkoutPlan(wp)&&setOpen(false)}><li key={wp.workoutPlan_id}>{wp.workoutPlan}</li></a>
          )}
        </ul>
      </div>
    )
  }

  //handles the dropdown selection  of a new workoutplan
  function changeWorkoutPlan(props) {
    //console.log('this is the selected workout plan ',props.workoutPlan)
    setOpen(!open)
    var flags={}
      var tempUniqueWorkoutDays =  allWorkoutPlans.filter(plan => plan.workoutPlan === props.workoutPlan).filter(function(entry){
        if (flags[entry.workoutDay]){
          return false
        }
        flags[entry.workoutDay] = true
        return true
      })
      tempUniqueWorkoutDays.sort((a,b) => {
        return a.workoutDayOrder-b.workoutDayOrder;
      })
      setUniqueWorkoutDays(tempUniqueWorkoutDays)
      //console.log('newly selected workout ', uniqueWorkoutDays)
  }

  return(
      <div>
        <h1 className='homeHeader'>Choose Your Workout</h1>
        <ul className='workoutDays'>
          {uniqueWorkoutDays.map(workout =>
            {if (workout.workoutDayOrder === props.user.nextWorkout) {
              return  (
                <Link className = 'nextLink' to = {`/home/${workout.workoutPlan_id}/${workout.workoutDay_id}`}>
            <li key= {workout.workoutDay_id} className = 'workoutDay'>
              {workout.workoutDay}
            </li>
            </Link>
              )
            }
            else{
              return(
                <Link className = 'link' to = {`/home/${workout.workoutPlan_id}/${workout.workoutDay_id}`}>
            <li key= {workout.workoutDay_id} className = 'workoutDay'>
              {workout.workoutDay}
            </li>
            </Link>
              )
              }
            }
          )}
        </ul>
        
        <div className='wp-change'>
          <a className="change-wp-button" href="#" onClick={()=>setOpen(!open)}>
            Change Workout Plan
          </a>
        </div>  
        {open?<DropdownMenu/>:<></>}
        
      </div>
    )
  }
export default Home;
