import React, {useState,useEffect} from 'react';
import '../styles/workout.css';
import {ReactComponent as MinusIcon} from '../icons/minus-icon.svg';

const ExerciseTile = (props) => {
    const [reps, setReps] = useState('')
    const [weight, setWeight] = useState('')
    useEffect(()=>{
        setReps(props.reps)
        setWeight(props.weight)
        console.log('hi this is the exercise set tile use effect')
    },[props.weight, props.reps])
return(
    <div className='workout-set-tile'>
        <h2 className='workout-set-tile-header'>{props.setTitle}</h2>
        <div className='workout-set-tile-rep-input'>
            <input className='workout-set-tile-input-field' value={reps} 
            onChange={(event) => props.handleRepsSetsCallback(event,props.exerciseIdentifier,props.setIdentifier, props.id,'reps')}
            ></input>
        </div>
        <div className='workout-set-tile-weight-input'>
            <input className='workout-set-tile-input-field' value={weight} onChange={(event) => props.handleRepsSetsCallback(event,props.exerciseIdentifier+1,props.setIdentifier,props.id,'weight')}></input>
        </div>
        <a href='#' className='minus-icon-button' onClick={()=>props.handleSetSubtractCallback(props.id)}>
            <MinusIcon width="100%" height="100%"/>
        </a>
    </div>
)
}

export default ExerciseTile