import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import '../styles/login.css';
import userService from '../services/users.js'
const Login = (props) => {

  let history = useHistory()
  //Variables for the username and password inputs
  const[username, setUsername] = useState('')
  const[password, setPassword] = useState('')
  const[openTakenUser,setTakenUser] = useState(false)
  const[createNewUser, setCreateNewUser] = useState(false)
  //handler for username state
  const handleUsernameChange = (event) => {
      setUsername(event.target.value)
    }
  //handler for password state
  const handlePasswordChange = (event) => {
      setPassword(event.target.value)
    }
  //checks if username and pasword match when submit is clicked
  const onSubmit = (event) => {
    event.preventDefault()
    //console.log('button clicked', event.target)
    props.users.map(x =>{
      if(x.username === username && x.password === password ){
        console.log('username matched')
        props.onSubmission(x)
        props.navDisplay(true)
        history.push('/home')
      }
    })
  }
  //function for adding a new user
  const addNewUser = (event) => {
    event.preventDefault()
    //checks if usernamd is taken
    function isUserTaken() {
      let found = false
      props.users.map(x =>{
      if(x.username === username){
        console.log('match')  
        found = true
        
        }

      })
      return found
    }
    
    let tempTaken = isUserTaken()
    //adds user to the database
    if (tempTaken === false){
      setTakenUser(false)
      const newDataObject = {
        username: username,
        password: password,
        workoutPlan:'',
        nextWorkout: 0
      }
      userService.create(newDataObject)
      props.onSubmission(newDataObject)
      props.navDisplay(true)
      history.push('/home')
    }
    else{
      setTakenUser(true)
    }
    
  }
  return(
    <div>
      <form onSubmit={onSubmit}>
        <div className = "login-form">
          <div className="login-inputs">
            Username: <input className ='input' value = {username}
            onChange={handleUsernameChange}
            />
          </div>
          <div className='login-inputs'>
            Password: <input className ='input' value = {password}
            type='password'
            onChange={handlePasswordChange}
            />
          </div>
          {createNewUser ? <button className='login-submit' onClick={addNewUser}>Create New User</button> : <button type="submit" className='login-submit'>LOGIN</button>}
        </div>
      </form>
      <div className='new-user-div'>
        {createNewUser?  <></> : <button className='new-user-button' onClick={()=>setCreateNewUser(!createNewUser)}>Create New User</button>}
        {openTakenUser ? <p className='username-available'>Username Taken</p>:<></>}
        <p className='guest-login'>Feel free to login with the username Guest and a password of 1 space</p>
      </div>
    </div>
  )
}
export default Login;
