import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip,Legend,ResponsiveContainer} from 'recharts'
import React from 'react'
const WorkoutExerciseChart = (props) =>{
    //console.log('this is the display chart data', exerciseChart)
    if (props.exerciseChart===null) {
      return(
        <div>
          <p className='no-data'>No Exercise Data Found</p>
        </div>
      )
      }
      else{
        let chartData = []
        //console.log('Exercise Chart: ',exerciseChart)
        props.exerciseChart.map(exercise => {
          let totalReps = 0
          let totalWeight = 0
          let estimatedMax = 0.0
          let maxWeight = 0
          let count = 0
          for (let i = 1; i<=6;i++){
            let tempRep = exercise['repsSet' + i]
            let tempWeight = exercise['weightSet'+i]
            if(!isNaN(tempRep) && !isNaN(tempWeight) && tempRep !=="" && tempWeight!==""){
              let tempEstimatedMax =  parseFloat(tempWeight*(1+(tempRep/30)))
              if (tempEstimatedMax > estimatedMax) {
                estimatedMax = Math.round(tempEstimatedMax)
              }
              if (tempWeight>maxWeight){
                maxWeight=tempWeight
              }
            }
          }
          
          if(estimatedMax!==0){
          var tempDate = new Date(exercise.submissionTime)
          chartData.push(
            {
              date: (tempDate.getMonth()+1)+"/"+tempDate.getDate()+"/"+tempDate.getFullYear(),
              estMax: estimatedMax,
              max: maxWeight
            }
          )
          }
        })
        chartData.reverse()
  
        return(
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={400} height = {400} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
              <Line name="Estimated Max" type="monotone" dataKey="estMax" stroke = "#45A29E" activeDot={{ r: 8 }} strokeWidth={3}/>
              <Line name="Max Weight per Workout" type="monotone" dataKey="max" stroke = "#CE84AD" activeDot={{ r: 8 }} strokeWidth={3}/>
              <Legend layout = "vertical" align = "center" verticalAlign = "top"></Legend>
              <CartesianGrid/>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        )
  
      }
  }
  export default WorkoutExerciseChart