import React, {useState,useEffect} from 'react';
import '../styles/workout.css';

const NotesSettings = (props) =>{
  var isExercise = function(x){
    return x.exercise === props.selectedExercise
  }
  //console.log("in the notes and settings")
  //console.log(props.exerciseList)
  
  var tempExercise = props.exerciseList.filter(isExercise)
  var tempnotes = ''
  var tempsettings = ''
  if (tempExercise.length>0){
    tempnotes = tempExercise[0].notes
    tempsettings = tempExercise[0].settings
  }
  else{
    tempnotes = ''
    tempsettings = ''
  }

  //const[notes, setNotes] = useState(props.currentNote)
  //const[settings, setSettings] = useState(props.currentSetting)
  //const[previousExercise, setPreviousExercise] = useState('')
  
  const handleNotesChange = (event) => {
    console.log(event.target.value)

    //setNotes(event.target.value)
    props.parentNotesCallback(event.target.value)
    //setPreviousExercise(props.selectedExercise)
  }
const handleSettingsChange = (event) => {
  props.parentSettingsCallback(event.target.value)
    console.log(event.target.value)
    //setSettings(event.target.value)
    //setPreviousExercise(props.selectedExercise)
  }
 function changeNote(event) {
   console.log('hi')
   console.log(props.notesSettings)
   console.log(props.selectedExercise)
  event.preventDefault() 
  let temp = ''
  if (props.notesSettings){
    temp = props.notesSettings.filter(x=>props.selectedExercise===x.exercise)[0].notes
  }
  //setNotes(temp)
 }
 function changeSetting(event) {
  console.log('hi')
  console.log(props.notesSettings)
  console.log(props.selectedExercise)
 event.preventDefault() 
 let temp = ''
 if (props.notesSettings){
   temp = props.notesSettings.filter(x=>props.selectedExercise===x.exercise)[0].settings
 }
 //setSettings(temp)
}
  
  
  return(
    <div className='notes-settings'>
      <div className='notes-settings-row'>
        <span className='notes-settings-header'>Notes:</span><input className = 'notes-settings-inputs' value = {props.currentNote} onClick = {changeNote} onChange = {handleNotesChange} />
      </div>
      <div className='notes-settings-row'>
        <span className='notes-settings-header'>Settings:</span><input className = 'notes-settings-inputs' value = {props.currentSetting} onClick = {changeSetting} onChange = {handleSettingsChange}/>
      </div>
    </div>
  )

}
export default NotesSettings;
