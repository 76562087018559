import {
  BrowserRouter as Router,
   Switch, Route, Link,
   useRouteMatch
} from "react-router-dom"
import {ReactComponent as HomeIcon} from './icons/barbell.svg';
import {ReactComponent as AnalysisIcon} from './icons/analysis.svg';
import {ReactComponent as EditIcon} from './icons/edit.svg';
import {ReactComponent as WorkoutPlanIcon} from './icons/brain.svg';
import {ReactComponent as CaretIcon} from './icons/caret.svg';
import {ReactComponent as BackIcon} from './icons/backArrow.svg';
import {ReactComponent as CreateIcon} from './icons/lightBulb.svg';

import React, {useState,useEffect,  useRef} from 'react'
import Login from './components/login'
import Home from './components/home'
import Analytics from './components/analytics'
import Workout from './components/workout'
import Create from './components/workoutPlanCreate'
import Edit from './components/workoutPlanEdit'
import workoutPlanService from './services/workoutPlans.js'
import userService from './services/users.js'
import './App.css'
import './styles/login.css'
import {CSSTransition} from 'react-transition-group'

function Navbar(props){
  return (
    <nav className="navbar">
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  );
}
function NavItem(props) {
  const [open, setOpen]  = useState(false);
  function handleOpen(){
    setOpen(!open)
  }

  return(
    <li className="nav-item">
      <a href="#" className="nav-button" onClick={()=>setOpen(!open)}>
        {props.icon}
      </a>
      {open && props.children}
    </li>
  );
}
function DropdownMenu(props){
  const [activeMenu, setActiveMenu] = useState('main')
  const [menuHeight,setMenuHeight] = useState(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
  }, [])

  function calcHeight(el) {
    const height = el.offsetHeight
    setMenuHeight(height)
  }

  function DropdownItem(props){
    return(
      <a href="#" className="menu-item" onClick={()=>props.goToMenu && setActiveMenu(props.goToMenu)}>
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </a>
    );
  }
  return(
    <div className="dropdown" style={{height:menuHeight}} ref={dropdownRef}>
      <CSSTransition 
      in={activeMenu==='main'} 
      timeout={500} 
      unmountOnExit 
      classNames="menu-primary" 
      onEnter={calcHeight}>
        <div className="menu">
          <Link to ="/home"><DropdownItem leftIcon={<HomeIcon/>}>Home</DropdownItem></Link>
          <Link to ="/analytics"><DropdownItem leftIcon={<AnalysisIcon/>}>Analytics</DropdownItem></Link>
          <DropdownItem leftIcon={<WorkoutPlanIcon/>} goToMenu="workoutPlanner">Workout Planner</DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition 
      in={activeMenu==='workoutPlanner'} 
      timeout={500} 
      unmountOnExit 
      onEnter={calcHeight} 
      classNames="menu-secondary">
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<BackIcon/>}>Back</DropdownItem>
          <Link to ="/create"><DropdownItem  leftIcon={<CreateIcon/>}>Create</DropdownItem></Link>
          <Link to ="/edit"><DropdownItem leftIcon={<EditIcon/>}>Edit</DropdownItem></Link>
        </div>
      </CSSTransition>
    </div>
  );
}



const App = () => {
  const [users,setUsers] = useState([])
  const [user, setUser] = useState(null)
  const [allWorkoutPlans,setAllWorkoutPlans] = useState([])
  const [workoutPlan,setWorkoutPlan] = useState([])
  const [displayNav, setDisplayNav] = useState(false)
  //pulls all users for login
  useEffect(() => {
    document.title = "Better Than Yesterday"
    userService
    .getAll()
      .then(response => {
        //console.log('promise fulfilled')
        setUsers(response)
      })
  }, [])

  //this sets the data once user is logged in from login component
  const handleUserSubmission = (tempUser) => {
    setUser(tempUser)
    //console.log('passed user',tempUser)
    workoutPlanService
        .getAll()
          .then(response => {
            console.log('promise fulfilled')
            setAllWorkoutPlans(response.filter(plan => plan.user === tempUser.username))
            setWorkoutPlan(response.filter(plan => plan.workoutPlan ===tempUser.workoutPlan))
          })
  //console.log("all workout plans",allWorkoutPlans)
  //console.log('default workout plan',workoutPlan)
  }

  const handleCreateWorkout = ()=>{
    workoutPlanService
        .getAll()
          .then(response => {
            console.log('promise fulfilled')
            setAllWorkoutPlans(response.filter(plan => plan.user === user.username))
          })
  }

  const handleWorkoutSubmission = (x) =>{
    let tempUser = user
    tempUser.nextWorkout = x
    setUser(tempUser)
  }
  //used to debug
  function test(x,y) {
    console.log('this is all workouts',x[1].workoutDay_id)
    console.log("this is the match",y)
    console.log("this is the match params", y.params)
    console.log("this is the match params id", y.params.workoutDay_id)
    console.log("does this work",x[1].workoutDay_id===parseInt(y.params.workoutDay_id))
  }

  //display the navbar once user is logged in
  const handleDisplayNav = (x) => {
    setDisplayNav(x)
    console.log('display nav')
  }

  //variables to match the specific workout day
  const match = useRouteMatch('/home/:workoutPlan_id/:workoutDay_id')
  const workout = match ? allWorkoutPlans.filter(x => x.workoutDay_id === parseInt(match.params.workoutDay_id) && x.workoutPlan_id===parseInt(match.params.workoutPlan_id)) : null

  return (
    <div>
      {user ? <em className="userTitle">Welcome {user.username}</em> : <Link to="/">Login</Link>}
      {displayNav ? 
        <Navbar>
          <NavItem  icon={<CaretIcon />}>
          <DropdownMenu/>
          </NavItem>
        </Navbar> : null
      }
      <Switch>
        <Route path = "/home/:workoutPlan_id/:workoutDay_id">
          <Workout workout={workout} user = {user} allWorkoutPlans = {allWorkoutPlans} handleWorkoutSubmission = {handleWorkoutSubmission}/>
        </Route>
        <Route path="/home">
          <Home  user = {user}/>
        </Route>
        <Route path="/analytics">
          <Analytics  user = {user}/>
        </Route>
        <Route path="/create">
          <Create user = {user} allWorkoutPlans = {allWorkoutPlans} createWorkout = {handleCreateWorkout}/>
        </Route>
        <Route path="/edit">
          <Edit user = {user}/>
        </Route>
        <Route path="/">
          <Login users = {users} onSubmission = {handleUserSubmission} navDisplay = {handleDisplayNav}/>
        </Route>
      </Switch>

    </div>
  );
}
 export default App;
