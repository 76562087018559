import React, {useState,useEffect} from 'react'
import '../styles/analytics.css';
import dataService from '../services/data.js'

import {ReactComponent as BackIcon} from '../icons/back.svg';
import {ReactComponent as NextIcon} from '../icons/next.svg';
const Analytics = (props) => {
  const [data, setData] = useState([])
  const [monthSelector, setMonthSelector] = useState(0)
  const [tableValues, setTableValues] = useState([])
  const hook = () =>{
    var tempPrevData = []
    dataService
      .getAll()
      .then(response => {
        tempPrevData = response.filter(x => x.user === props.user.username)
        setData(tempPrevData)
        console.log('this is the user entries',tempPrevData)

        
      })
    let today = new Date()
    let currentIndex = today.getMonth()
    setMonthSelector(currentIndex)
    
  }
  useEffect(hook, [])

  function decrementMonth(){
    let x = monthSelector
    x-=1
    setMonthSelector(x)
  }

  function incrementMonth(){
    let x = monthSelector
    x+=1
    setMonthSelector(x)
  }
  function displayCalendar() {
    var today = new Date()

    //var months = [lastLastIndex, lastIndex, currentIndex]
    var monthYears = [2022, 2022, 2022, 2022, 2022, 2022, 2022, 2022, 2022, 2022, 2022, 2022]
    var monthDays  = [31,28,31,30,31,30,31,30,31,31,30,31]

    var trueCount  = new Array(12).fill(0)

    var monthNames = {
      0:"January",
      1:"February",
      2:"March",
      3:"April",
      4:"May",
      5:"June",
      6:"July",
      7:"August",
      8:"September",
      9:"October",
      10:"November",
      11:"December"
    }
    
    var allMonths = []
    var monthStart = []
    monthDays.forEach((element,index)=>{
      allMonths.push(new Array(element).fill(false))
      monthStart.push((new Date(monthYears[index],index,1)).getDay())
    })

    data.forEach(element => {
      let tempDate = new Date(element.submissionTime)
      let tempDay = tempDate.getDate()
      let tempMonth = tempDate.getMonth()
      let tempYear = tempDate.getFullYear()
      trueCount.forEach((loopMonth, index)=>{
        if (tempMonth === index && tempYear === monthYears[index] && allMonths[index][tempDay-1] ===false){
          allMonths[index][tempDay-1] = true
          trueCount[index]+=1
        }
      })
    })

    console.log('All Months: ', allMonths)
    console.log('month Starts',monthStart)
    var tableValues = [
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
      [[],[],[],[],[],[]],
    ]
    
    for (let i = 0; i <= 5; i++) {
       for(let j = 0; j<12;j++){
         for(let k = 0; k<7;k++){
          let dayIndex =  ((i)*7 + k-monthStart[j])
          if(i===0 && k< monthStart[j]){
             tableValues[j][i].push(<td className='calendar-cell-out'></td>)
           }
           else if(dayIndex <monthDays[j]){
            if(allMonths[j][dayIndex]===true){
              tableValues[j][i].push(<td className='calendar-cell-true'>{dayIndex+1}</td>)
            }
            else{
              tableValues[j][i].push(<td className='calendar-cell-false'>{dayIndex+1}</td>)
            }
           }
           else{
             tableValues[j][i].push(<td className='calendar-cell-out'></td>)
           }
         }
       }      
    }
    console.log('these are the table values',tableValues[0])

    return(
      <div className='calendar-container'>
        
        
        <div className='table-container'>
          <a className="month-button" href="#" onClick={()=>decrementMonth()}><span className='icon'><BackIcon/></span></a>

                <table className='calendar-table'>
                <thead>
                  <th className='calendar-header' colSpan="7">{monthNames[monthSelector].toUpperCase()}</th>
                </thead>
                 <thead>
                 <th className='calendar-sub-header'>S</th>
                 <th className='calendar-sub-header'>M</th>
                 <th className='calendar-sub-header'>T</th>
                 <th className='calendar-sub-header'>W</th>
                 <th className='calendar-sub-header'>T</th>
                 <th className='calendar-sub-header'>F</th>
                 <th className='calendar-sub-header'>S</th>
                 </thead>
                  <tbody className='table-body'>
                    {tableValues[monthSelector].map(row=>{
                      return(
                      <tr>
                        {row}
                      </tr>
                      )
                    })}
                  </tbody>
                </table>
          <a className="month-button" href="#" onClick={()=>incrementMonth()}><span className='icon'><NextIcon/></span></a>
        </div>
        
        <p className='ratio'>ACTIVE: {trueCount[monthSelector]} DAYS!</p>
      </div>
    )
  }
  return(
    <div>
      <h1 className='header-text'>ANALYTICS</h1>
      {displayCalendar()}
    </div>
  )
}

export default Analytics;
