import React, {useState,useEffect} from 'react'
import workoutPlanService from '../services/workoutPlans.js'
import {Styles,EditableCell,Table} from './reactEditableTableEdit'
import '../styles/edit.css';
const EditWorkoutPlan = (props) => {
    
    const [allWorkoutPlans,setAllWorkoutPlans] = useState([])
    const [uniqueWorkoutPlan, setUniqueWorkoutPlan] = useState([])
    const [selectedWorkoutPlan, setSelectedWorkoutPlan] = useState([])
    const [workoutPlan,setWorkoutPlan] = useState([])
    const [skipPageReset, setSkipPageReset] = useState(false)
    const [originalData] = useState([])
    const columns = React.useMemo(
        () => [
          {
            Header: 'Exercise',
            accessor: 'exercise',
          },
          {
            Header: 'Sets',
            accessor: 'sets',
          },
        ],
          []
        )

        const updateMyData = (rowIndex, columnId, value) => {
            // We also turn on the flag to not reset the page
            setSkipPageReset(true)
            setSelectedWorkoutPlan(old =>
              old.map((row, index) => {
                if (index === rowIndex) {
                  return {
                    ...old[rowIndex],
                    [columnId]: value,
                  }
                }
                return row
              })
            )
          }
          
          // After data chagnes, we turn the flag back off
          // so that if data actually changes when we're not
          // editing it, the page is reset
          React.useEffect(() => {
            setSkipPageReset(false)
          }, [selectedWorkoutPlan])
          
          // Let's add a data resetter/randomizer to help
          // illustrate that flow...
          const resetData = () => setSelectedWorkoutPlan(originalData)
      
    useEffect(() => {
            workoutPlanService
            .getAll()
            .then(response => {
                console.log('promise fulfilled')
                setAllWorkoutPlans(response)
                //setWorkoutPlan(response.data.filter(plan => plan.workoutPlan === props.user.workoutPlan))
                console.log(response)
                //console.log(props.user)
                //console.log("workout Plan  ",  response.data.filter(plan => plan.workoutPlan ===tempUser[0].workoutPlan))
                const flags={}
                const tempUniqueWorkoutDays =  response.filter(plan => plan.user === props.user.username).filter(function(entry){
                if (flags[entry.workoutPlan]){
                    return false
                }
                flags[entry.workoutPlan] = true
                return true
                })
                setUniqueWorkoutPlan(tempUniqueWorkoutDays)
            })
            }, [])

    const handleWorkoutPlanSelection = (wp) =>{
      setWorkoutPlan(wp)
      setOpen(!open)
      var isWp = function(x){
        return x.workoutPlan === wp
      }
      var temp = (allWorkoutPlans.filter(isWp))
      setSelectedWorkoutPlan(temp)
      console.log("Changed the workoutplan to ",workoutPlan)
    }
    const selectWorkoutPlan = () => {
      
        //console.log(selectedWorkoutPlan)
        const flags={}
                const tempUniqueWorkoutDays =  selectedWorkoutPlan.filter(function(entry){
                if (flags[entry.workoutDayOrder]){
                    return false
                }
                flags[entry.workoutDayOrder] = true
                return true
                })

        console.log("This is the selectedWorkoutPlan",selectedWorkoutPlan)
        console.log("this is the workout plan",workoutPlan)
        return(
            <div className='workout-days'>
        
            {tempUniqueWorkoutDays.map(x =>{
                console.log("this is the temp workout Days",x)
                return(
                  <div className='workout-day'>
                  <h1 className='workoutDayHeader'>{x.workoutDay}</h1>
                    <React.Fragment>
                        <Styles>
                            <Table
                            columns={columns}
                            workoutDayOrder = {x.workoutDayOrder}
                            workoutPlan = {workoutPlan}
                            data={selectedWorkoutPlan}
                            updateMyData={updateMyData}
                            skipPageReset={skipPageReset}
                            pageSize = {100}
                            showPagination = {false}
                            />
                        </Styles>
                    </React.Fragment>
                  </div>
                )
            })  
            }
            </div>
        )
    }
    
    const displayWorkoutPlans = () =>{
        console.log("this is the unique workoutplans",uniqueWorkoutPlan)
        console.log("hi")
        console.log(uniqueWorkoutPlan.length)
        
        return (
            <div>
                    {
                      uniqueWorkoutPlan.map(element => {
                        return <a href="#" onClick={(event) =>handleWorkoutPlanSelection(event,element.workoutPlan)}>{element.workoutPlan}</a>
                    })
                    } 
            </div>
        )        
    }
    const handlingDisplayWorkoutPlans = () => {
      if (uniqueWorkoutPlan.length > 0) {
        return displayWorkoutPlans()
      }
      else{
        <h1>Go create a workout plan</h1>
      }
    }

    const handlingSelectWorkoutPlan = () => {
      
      if (uniqueWorkoutPlan.length > 0) {
        console.log("display select workout plan",selectedWorkoutPlan)
        return selectWorkoutPlan()
      }
      else{
        <h1>hi</h1>
      }
    }

    const handleSubmitChanges = () => {
      //event.preventDefault()
      console.log(selectedWorkoutPlan)
      selectedWorkoutPlan.map(wp=>{
        var tempWp = {
          exercise:wp.exercise, 
          sets:wp.sets
        }
        workoutPlanService.update(wp.id,tempWp)
      }
        )
    }

    const [open, setOpen]  = useState(false);
        function DropdownMenu(){
          
          function DropdownItem(props){
            return(
              <a href="#" className="menu-item">
                {props.children}
              </a>
            );
          }
          return(
            <div className='wp-dropdown'>
              <ul  className = 'wp-dropdown-list'>
              {uniqueWorkoutPlan.map(wp=>
              <a href="#"  className = 'wp-dropdown-item'  onClick={()=>handleWorkoutPlanSelection(wp.workoutPlan)}><li>{wp.workoutPlan}</li></a>
                )}
              </ul>
            </div>
          );
        }
    return (
      <div>
        <div>
            <h1 className='headerText'>Edit</h1>
            <div className='wp-change'>
              <a className="change-wp-button" href="#" onClick={(event)=>handleSubmitChanges(event)}>
                Submit Edits
              </a>
                  
              <a className="change-wp-button" href="#" onClick={()=>setOpen(!open)}>
                Edit Workout Plan
              </a>
            </div> 
            {open?<DropdownMenu/>:<></>}
        </div>
        <div>
          {handlingSelectWorkoutPlan()}
        </div>
      </div>
      )
}
 
export default EditWorkoutPlan;