import React, {useState,useEffect} from 'react';
import ExerciseTile from './ExerciseTile'
import ExerciseSetTile from './ExerciseSetTile'
import '../styles/workout.css';
import {ReactComponent as PlusIcon} from '../icons/plus-icon.svg';

const WorkoutInterface = (props) => {
    const [setsObjectArray2, setSetsObjectArray2] = useState([])

    useEffect(()=>{
        setSetsObjectArray2(props.setsObjectArray)
        console.log('hi this is the exercise workoutInterface use effect')
    },[props.setsObjectArray])


    function makeExerciseTiles() {
        if(setsObjectArray2.length === 0){
            return(<div></div>)
        }
        else{
        return(
            <div className='exercise-tiles-parent'>
                <div className='reps-weight-label'>
                    <div></div>
                    <h3>Reps</h3>
                    <h3>Weight</h3>
                </div>
                <div className='exercise-tiles'>
                    {(setsObjectArray2[props.setsObjectArrayId].record).map(x => {
                        return(
                        <ExerciseSetTile handleRepsSetsCallback = {props.handleRepsSetsCallback} handleSetSubtractCallback = {props.handleSetSubtractCallback} reps = {x.reps} weight = {x.weight} setTitle = {x.setTitle} setIdentifier = {x.setIdentifier} exerciseIdentifier = {props.selectedId} id = {x.id}/>
                        )
                    })}
                </div>
                <a href='#' className='plus-icon-button' onClick={()=>props.handleSetAddCallback()}>
                    <PlusIcon width="100%" height="100%"/>
                </a>
                
            </div>
        )
            }
    }

//<ExerciseSetTile handleRepsSetsCallback = {props.handleRepsSetsCallback}
//<ExerciseTile exercise = {tempExercise} id = {tempId} handleExerciseChangeCallback = {props.handleExerciseChangeCallback} handleExerciseSelection = {props.handleExerciseSelection}/>
    return(
        <div>
            {makeExerciseTiles()}
        </div>
    )
}

export default WorkoutInterface