import React, {useState,useEffect} from 'react'
import WorkoutInterface from './WorkoutInterface.js'
import NotesSettings from './notes_Settings.js'
import PreviousData from './PreviousData.js'
import WorkoutExerciseChart from './WorkoutExerciseChart'
import dataService from '../services/data.js'
import exerciseService from '../services/exerciseList.js'
import exerciseListService from '../services/exerciseList.js'
import userService from '../services/users.js'
import '../styles/workout.css';
import { useHistory } from 'react-router-dom';


const Workout = (props) => {
  let history = useHistory()
  const [notesSettings, setNotesSettings] = useState([])
  const [currentNote, setCurrentNote] = useState('')
  const [currentSetting, setCurrentSetting] = useState('')
  const [currentExercises, setCurrentExercises] = useState([])
  const [setsObjectArray,setSetsObjectArray] = useState([])
  const [setsObjectArrayId, setSetsObjectArrayId] = useState(0)
  const [boolInterface, setBoolInterface] = useState(false)
  

  //Sets up objects for notes and workout table data
  
  const hook = () => {
  
    var tempPrevData = []
    dataService
      .getAll()
      .then(response => {
        tempPrevData = response
        setPreviousExerciseData(tempPrevData)
      })
    var tempExerciseList = []
      exerciseListService
      .getAll()
      .then(response => {
        tempExerciseList = response
        tempExerciseList = tempExerciseList.filter(x => x.user === props.user.username)
        setExerciseList(tempExerciseList)
        setNotesSettings(tempExerciseList)
        const tempCurrentExercises = []
        const tempData = []
        var count = 0
        
        props.workout.sort((a,b) => {
          return a.order - b.order;
        })
        
        var tempSetObjectList = []
        var countId = 0

        props.workout.forEach(x =>{
          var numSets = x.sets
          var tempSetsList = []
          for (let i = 1; i <= numSets; i++) {
              let set = 'set'+i
              let tempRep = ''
              let tempWeight = ''
              tempSetsList.push({"id": i, "setIdentifier":set,"setTitle":('Set ' + i),"reps":tempRep,"weight":tempWeight})
          }

          tempSetObjectList.push({
            id: countId,
            workoutPlan: x.workoutPlan,
            workoutDay: x.workoutDay,
            exercise: x.exercise, 
            order: x.order, 
            sets: x.sets,
            record: tempSetsList
          })
          countId++

          tempCurrentExercises.push({
            id: count/2,
            exercise: x.exercise
          })

          count++
          count++

      })
        //console.log("this is the temp current exercises",tempCurrentExercises)
        //console.log('this is the exercise list',tempExerciseList)
        //console.log('this is the tempNotesSettings',tempNotesSettings)
        //console.log('this is the SetsObjectArray----------------------------------------------------')
        //console.log(tempSetObjectList)
        //console.log(tempSetObjectList[0])
        //console.log(tempSetObjectList[0].record)
        
        setSetsObjectArray(tempSetObjectList)
        setSetsObjectArrayId(0)
        setData(tempData)
        setCurrentExercises(tempCurrentExercises)
        setBoolInterface(true)
      })
}
useEffect(hook, [])

function handleNotesCallback(note){
  //console.log("in the handle note callback function")
  let updateValue = [...notesSettings]
  updateValue.forEach((element,index) => {
  //console.log('element exercise is ', element.exercise,' and selected Exercise is ',selectedExercise)
  if(element.exercise.toLowerCase() === selectedExercise.toLowerCase()){
    updateValue[index].notes = note
  }
})
//console.log(updateValue)
setNotesSettings(updateValue)
setCurrentNote(note)
}

function handleSettingsCallback(setting){
  let updateValue = [...notesSettings]
  updateValue.forEach((element,index) => {
    //console.log('element exercise is ', element.exercise,' and selected Exercise is ',selectedExercise)
    if(element.exercise.toLowerCase() === selectedExercise.toLowerCase()){
      updateValue[index].settings = setting
    }
  })
  //console.log(updateValue)
  setNotesSettings(updateValue)
  setCurrentSetting(setting)
}

  //creating states for this page
  const [data, setData] = useState([])
  const [exerciseList, setExerciseList] = useState([])
  const [previousExerciseData, setPreviousExerciseData] = useState([])
  const [exerciseDisplay,setExerciseDisplay]  = useState([])
  const [exerciseChart, setExerciseChart] = useState([])
  const [selectedExercise, setSelectedExercise] = useState("")
  const [selectedId, setSelectedId] = useState(0)
  
  //determines the data to present in the previous data table based on the selected cell
  const previousWorkouts = (index) => {
    var currentExercise = setsObjectArray[index].exercise
    console.log('this is the current exercise: ',currentExercise)
    var isExercise = function(x){
      return x.exercise.toLowerCase() === currentExercise.toLowerCase()
    }
    
    var exerciseEntries =  previousExerciseData.filter(isExercise).filter(dataInstance => dataInstance.user === props.user.username)
    //console.log('these are the exercise entries',exerciseEntries)
    if (exerciseEntries.length>3){
      exerciseEntries.sort((a,b)=>(a.submissionTime<b.submissionTime) ? 1 : -1)
      setExerciseDisplay([exerciseEntries[0],exerciseEntries[1],exerciseEntries[2]])
      setSelectedExercise(exerciseEntries[0].exercise)
      setExerciseChart(exerciseEntries)
      //console.log('latest 3 exercises',exerciseDisplay)
    }
    else if(exerciseEntries.length>0){
      exerciseEntries.sort((a,b)=>(a.submissionTime<b.submissionTime) ? 1 : -1)
      setExerciseDisplay(exerciseEntries)
      setSelectedExercise(exerciseEntries[0].exercise)
      setExerciseChart(exerciseEntries)
    }
    else{
      setExerciseDisplay(null)
      setSelectedExercise(currentExercise)
      setExerciseChart(null)
    }
    //console.log('this is the notesSettings', notesSettings)
    //These values are passed to the note setting component
    //console.log('this is in the previous data function',notesSettings.filter(x=> x.exercise===currentExercise))
    var exerciseMatch = notesSettings.filter(x=> x.exercise.toLowerCase()===currentExercise.toLowerCase())
    console.log('This is the exercise match value ', exerciseMatch)
    let tempNote = exerciseMatch.length!==0 ? notesSettings.filter(x=> x.exercise.toLowerCase()===currentExercise.toLowerCase())[0].notes: ''
    let tempSetting = exerciseMatch.length!==0 ? notesSettings.filter(x=> x.exercise.toLowerCase()===currentExercise.toLowerCase())[0].settings: ''
    setCurrentNote(tempNote === null ? '' : tempNote)
    setCurrentSetting(tempSetting === null ? '' : tempSetting)
  }

//This is called when the workout is submitted
const  addWorkout  = event =>{
  event.preventDefault()
  for(let i =0; i < setsObjectArray.length;i++){
    console.log('hi this is i ', i)
    let exerciseReps = ['','','','','','']
    let exerciseWeight = ['','','','','','']
    let recordLength = setsObjectArray[i].record.length
    console.log('record length')
    console.log(recordLength)
    
    let tempRecord = setsObjectArray[i].record
    console.log('temp record')
    console.log(tempRecord)
    for (let j = 0; j < recordLength;j++){
      console.log('hi this is j ', j)
      exerciseReps[j] = tempRecord[j].reps
      exerciseWeight[j] = tempRecord[j].weight
    }
    const newDataObject ={
      workoutPlan: setsObjectArray[i].workoutPlan,
      workoutDay: setsObjectArray[i].workoutDay,
      workoutDayOrder:props.workout[0].workoutDayOrder,
      submissionTime: Date.now(),
      user: props.user.username,
      exercise: setsObjectArray[i].exercise,
      order: setsObjectArray[i].order,
      sets: setsObjectArray[i].sets,
      repsSet1:exerciseReps[0],
      repsSet2:exerciseReps[1],
      repsSet3:exerciseReps[2],
      repsSet4:exerciseReps[3],
      repsSet5:exerciseReps[4],
      repsSet6:exerciseReps[5],
      weightSet1:exerciseWeight[0],
      weightSet2:exerciseWeight[1],
      weightSet3:exerciseWeight[2],
      weightSet4:exerciseWeight[3],
      weightSet5:exerciseWeight[4],
      weightSet6:exerciseWeight[5]
    }
  
        dataService.create(newDataObject)

        var exercise_id = 0
        const isFound = exerciseList.some(element =>{
        
          if( element.exercise === setsObjectArray[i].exercise && element.user === props.user.username){
            exercise_id = element.id
            return true
          }
        })
        if(isFound===true){
          //console.log('updated exercise  entry for',newDataObject.exercise)
          const exerciseUpdate = {
            settings: notesSettings.filter(x=>newDataObject.exercise===x.exercise)[0].settings,
            notes: notesSettings.filter(x=>newDataObject.exercise===x.exercise)[0].notes
          }
          exerciseService.update(exercise_id,exerciseUpdate)
        }
        else{
          //console.log('created exercise  entry for',newDataObject.exercise)
          const newNS = {
          user: props.user.username,
          exercise: newDataObject.exercise,
          settings: "",
          notes: ""
        }

        exerciseService.create(newNS)

        }
      }
        //}
      
        var flags={}
        var tempUniqueWorkoutDays =  props.allWorkoutPlans.filter(plan => plan.workoutPlan === props.workout[0].workoutPlan).filter(function(entry){
          if (flags[entry.workoutDay]){
            return false
          }
          flags[entry.workoutDay] = true
          return true
        })
        //console.log('unique days', tempUniqueWorkoutDays)
        //console.log(tempUniqueWorkoutDays.length)
        if (tempUniqueWorkoutDays.length <= props.workout[0].workoutDay_id) {
          //console.log('last day')
          const tempUser = {nextWorkout: 0,workoutPlan: props.workout[0].workoutPlan}
          userService.update(parseInt(props.user.id),tempUser)
          props.handleWorkoutSubmission(0)
        }
        else {
          //console.log('next day')
          //console.log('this is the user id',props.user.id)
          const tempUser = {nextWorkout: props.workout[0].workoutDayOrder+1,workoutPlan: props.workout[0].workoutPlan}
          //console.log(tempUser)
          userService.update(parseInt(props.user.id),tempUser)
          props.handleWorkoutSubmission(props.workout[0].workoutDayOrder+1)
        }
  setTimeout(() => {history.push('/analytics')}, 250)
}

function displayExercises(){
  function changeExercise(exercise, id){
    //console.log('this is the id ', id)
    setSelectedExercise(exercise)
    setSelectedId(id)
    setSetsObjectArrayId(id)
    var currentExercise = exercise
    var isExercise = function(x){
      return x.exercise === currentExercise
    }
    
    var exerciseEntries =  previousExerciseData.filter(isExercise).filter(dataInstance => dataInstance.user === props.user.username)
    //console.log('these are the exercise entries',exerciseEntries)
    if (exerciseEntries.length>3){
      exerciseEntries.sort((a,b)=>(a.submissionTime<b.submissionTime) ? 1 : -1)
      setExerciseDisplay([exerciseEntries[0],exerciseEntries[1],exerciseEntries[2]])
      setSelectedExercise(exerciseEntries[0].exercise)
      setExerciseChart(exerciseEntries)
      //console.log('latest 3 exercises',exerciseDisplay)
    }
    else if(exerciseEntries.length>0){
      exerciseEntries.sort((a,b)=>(a.submissionTime<b.submissionTime) ? 1 : -1)
      setExerciseDisplay(exerciseEntries)
      setSelectedExercise(exerciseEntries[0].exercise)
      setExerciseChart(exerciseEntries)
    }
    else{
      setExerciseDisplay(null)
      setSelectedExercise(currentExercise)
      setExerciseChart(null)
    }
    //console.log('this is the notesSettings', notesSettings)
    //These values are passed to the note setting component
    //console.log('this is in the previous data function',notesSettings.filter(x=> x.exercise===currentExercise))
    var exerciseMatch = notesSettings.filter(x=> x.exercise.toLowerCase()===currentExercise.toLowerCase())
    console.log('This is the exercise match value ', exerciseMatch)
    let tempNote = exerciseMatch.length!==0 ? notesSettings.filter(x=> x.exercise.toLowerCase()===currentExercise.toLowerCase())[0].notes: ''
    let tempSetting = exerciseMatch.length!==0 ? notesSettings.filter(x=> x.exercise.toLowerCase()===currentExercise.toLowerCase())[0].settings: ''
    setCurrentNote(tempNote === null ? '' : tempNote)
    setCurrentSetting(tempSetting === null ? '' : tempSetting)
  }
  
  function handleExerciseChange(event, exercise, id){
    console.log(event.target.value)
    let tempCurrentExercises = [...currentExercises]
    tempCurrentExercises[id].exercise = event.target.value
    setCurrentExercises(tempCurrentExercises)
    let tempSetsObjectArray = setsObjectArray
    tempSetsObjectArray[id].exercise = event.target.value
    setSetsObjectArray(tempSetsObjectArray)
  }

  return(
    <div className='workout-header-div'>
      
        {currentExercises.map(x =>{
          return(
            <input className='workout-header-input' value={x.exercise} onClick={function(event){changeExercise(x.exercise, x.id);previousWorkouts(x.id)}} onChange={function(event){handleExerciseChange(event,x.exercise,x.id);previousWorkouts(x.id)}}></input>   
          )
        })}
    </div>
  )
}

function handleExerciseChangeCallback(event, exercise, id){
  console.log(event.target.value)
  let tempCurrentExercises = [...currentExercises]
  tempCurrentExercises[id].exercise = event.target.value
  setCurrentExercises(tempCurrentExercises)
}

function handleExerciseSelection(exercise, id) {
  console.log('this is the id ', id)
  setSelectedExercise(exercise)
  setSelectedId(id)
  setSetsObjectArrayId(id)
}

function handleRepsSetsCallback (event, id, set, setId,repsWeight){
  let tempSetObjectArray = setsObjectArray
  tempSetObjectArray[setsObjectArrayId].record[setId-1][repsWeight] = event.target.value
  setSetsObjectArray([...tempSetObjectArray])
  console.log('object array id')
  console.log(setsObjectArrayId)
  console.log(tempSetObjectArray)
}
function handleSetAddCallback (){
  let numSets = setsObjectArray[setsObjectArrayId].record.length
  if(numSets<6){
    let tempSetObjectArray = setsObjectArray
    let tempRecord = {"id": numSets+1, "setIdentifier":'set'+(numSets+1),"setTitle":('Set ' + (numSets+1)),"reps":'',"weight":''}
    tempSetObjectArray[setsObjectArrayId].record.push(tempRecord)
    setSetsObjectArray(tempSetObjectArray)
  }
}
function handleSetSubtractCallback (id){
  let tempSetObjectArray = setsObjectArray
  tempSetObjectArray[setsObjectArrayId].record.splice(id-1,1)
  let numSets = tempSetObjectArray[setsObjectArrayId].record.length
  for (let i=1;i<=numSets;i++){
    tempSetObjectArray[setsObjectArrayId].record[i-1].id = i
    tempSetObjectArray[setsObjectArrayId].record[i-1].setIdentifier = "set"+i
    tempSetObjectArray[setsObjectArrayId].record[i-1].setTitle ="Set "+i
  }
  console.log('deleted set here is the new array')
  console.log(tempSetObjectArray)
  setSetsObjectArray(tempSetObjectArray)
}

return (
<div>
  <h1 className='workoutHeader'><span className='headerText-workout'>{props.workout[0].workoutDay}</span></h1>
  {displayExercises()}
  
  {
  boolInterface === true &&
  <WorkoutInterface setsObjectArray = {setsObjectArray} setsObjectArrayId = {setsObjectArrayId} selectedExercise = {selectedExercise} selectedId = {selectedId} handleRepsSetsCallback = {handleRepsSetsCallback} handleExerciseChangeCallback = {handleExerciseChangeCallback} handleExerciseSelection = {handleExerciseSelection} handleSetAddCallback = {handleSetAddCallback} handleSetSubtractCallback = {handleSetSubtractCallback}/>
  }
  
    <div>
      <button className='submit-button' onClick={addWorkout}>Submit Workout</button>
    </div>
    <div className='previous-data'>
      <div className='prev-data-table'>
        <h1 className='previousExerciseHeader'><span className='previousExercise'>{selectedExercise}</span></h1>
        <NotesSettings selectedExercise= {selectedExercise} exerciseList= {exerciseList} notesSettings={notesSettings} parentNotesCallback = {handleNotesCallback} parentSettingsCallback={handleSettingsCallback} currentNote={currentNote} currentSetting={currentSetting}/>
        <PreviousData exerciseDisplay = {exerciseDisplay}/>
      </div>
      <div className='prev-data-chart'>
        <WorkoutExerciseChart exerciseChart = {exerciseChart}/>  
      </div>
    </div>
    
</div>
)
}
export default Workout;
