import React, {useState,useEffect} from 'react'
import {Styles,EditableCell,Table} from './reactEditableTableCreate'
import { useHistory } from 'react-router-dom';
import workoutPlanService from '../services/workoutPlans.js'
import '../styles/create.css';

const CreateWorkoutPlan = (props) => {
  let history = useHistory()
  const [allWorkoutPlans,setAllWorkoutPlans] = useState([])
  const [allWorkoutPlans2,setAllWorkoutPlans2] = useState([])
  useEffect(() => {
    workoutPlanService
    .getAll()
    .then(response => {
      //console.log('promise fulfilled')
      //console.log('these are the workout plans',response)
      //console.log('this is the user',props.user)
      setAllWorkoutPlans(response.filter(plan => plan.user === props.user.username))
      setAllWorkoutPlans2(response)
    })
  }, [])  
  
  //handles the input value changes
  const handleWorkoutPlanChange = (event) => {
      console.log(event.target.value)
      setWorkoutPlan(event.target.value)
  }

  //handles the change in workoutday name
  const handleWorkoutDayChange = (event,tempOrder) => {
    var tempData = [...workoutDaysPlease]
    tempData[tempOrder].workoutDayName = event.target.value
    setWorkoutDaysPlease(tempData)
  }

  //adds the workoutplan to the datbase
  const submitWorkoutPlan = (event) =>{
    event.preventDefault()
    var maxWorkoutPlanid =Math.max(...allWorkoutPlans2.map(wp=>wp.workoutPlan_id))+1
    //console.log('this is the user', props.user.username)
    //console.log('these are all the workout plans',allWorkoutPlans)
    //console.log('user plans',userPlans)
    //console.log('max wp id', maxWorkoutPlanid)
    //console.log('this is the data', data)
    data.forEach(element => {
      const newDataObject = {
        user : props.user.username,
        workoutPlan : workoutPlan,
        workoutDayOrder : element.workoutDayOrder,
        workoutDay: workoutDaysPlease[element.workoutDayOrder].workoutDayName,
        exercise: element.exercise,
        order : element.order,
        sets : element.sets,
        workoutPlan_id: maxWorkoutPlanid,
        workoutDay_id: element.workoutDayOrder+1
      }
      console.log(newDataObject)
      workoutPlanService
      .create(newDataObject)
        /*.then(response => {
          console.log(response) 
        })*/
    })
    setTimeout(() => {props.createWorkout()}, 250)
    setTimeout(() => {history.push('/home')}, 250)
  }

  //performed when add workout day button is pressed
  const addWorkoutDay = (event) => {
    const tempData = data
    tempData.push({
      workoutDayOrder : numWorkoutDays,
      order: 0,
      exercise : "",
      sets: ""
    })
    const tempData2 = workoutDaysPlease
    tempData2.push({
      workoutDayOrder : numWorkoutDays,
      workoutDayName :  "",
      numExercises: 1
    })
    setData(tempData)
    setWorkoutDaysPlease(tempData2)
    setNumWorkoutDays(numWorkoutDays+1)
    //console.log(data)
    //console.log(numWorkoutDays)
    //console.log(columns)
    //workoutDays()
  }

  const addExercise = (event,x) =>{
    event.preventDefault()
    const tempData = data
    var isWorkoutDay = function(workoutDay){
      return workoutDay.workoutDayOrder === x
          }
    var tempExercises = data.filter(isWorkoutDay)
    var tempOrder = tempExercises.map((exercise) => exercise.order)
    var nextOrder = Math.max(...tempOrder)+1
    //console.log("this is the temp exercises",tempExercises,"this is the next order ",nextOrder," this is the temp order ",tempOrder," this is the max ",Math.max(tempOrder))
    tempData.push({
      workoutDayOrder : x,
      order : parseInt(nextOrder),
      exercise : "",
      sets : ""
    })
    setData(tempData)
    let tempData2 = workoutDaysPlease
    tempData2[x].numExercises = tempData2[x].numExercises+1
    setWorkoutDaysPlease(tempData2)
    //console.log("Added Exercise: ",data)
    //workoutDays()
  }
  const removeExercise = (event,x)=>{
    event.preventDefault()
    console.log('thi si sthe data ',data)
  }

  //this creates the  table for the  workout day
  const workoutDays = () =>{
    return(
      <div className='workoutdays-container'>
        {workoutDaysPlease.map(x => {
          return(
            <div className='workoutplan-day'>

              Workout Day <input value = {workoutDaysPlease[x.workoutDayOrder].workoutDayName}
              onChange={(event) => handleWorkoutDayChange(event,x.workoutDayOrder)}
              />
            <React.Fragment>
                <Styles>
                    <Table
                    columns={columns}
                    workoutDayOrder = {x.workoutDayOrder}
                    data={data}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                    pageSize = {100}
                    showPagination = {false}
                    />
                </Styles>
              </React.Fragment>
            <a className='add-exercise-button' href="#" onClick={(event) => addExercise(event, x.workoutDayOrder)}>Add Exercise</a>
            <a className='add-exercise-button' href="#" onClick={(event) => removeExercise(event, x.workoutDayOrder)}>Remove Exercise</a>
            </div>
            )
        })}
      </div>
    )   
  }

  const columns = React.useMemo(
      () => [
        {
          Header: 'Exercise',
          accessor: 'exercise',
        },
        {
          Header: 'Sets',
          accessor: 'sets',
        },
      ],
        []
      )
  const [data, setData] = useState([])
  const [workoutPlan, setWorkoutPlan] = useState("")
  const [numWorkoutDays, setNumWorkoutDays] = useState(0)
  const [workoutDaysPlease, setWorkoutDaysPlease] = useState([])
  const [skipPageReset, setSkipPageReset] = useState(false)
  
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  
  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }
  
    return (
        <div>
            <h1 className='header-text'>Create</h1>
            <div className='workoutplan-name'>
              <div className='workoutplan-name-child'>
            Name Workout Plan <input value = {workoutPlan}
            onChange={handleWorkoutPlanChange}
            />
            </div>
            </div>
            <div className='button-container'>
              <a className='button' href="#" onClick={submitWorkoutPlan}>Submit Workout Plan</a>
              <a className='button' href="#" onClick={addWorkoutDay}>Add Workout Day</a>
              
            </div>
            <div>
              
              {workoutDays()}
            </div>   
        </div>
      );
}
 
export default CreateWorkoutPlan;