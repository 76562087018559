import React, {useState,useEffect} from 'react';
import '../styles/workout.css';
//this displays the table for the previous data
const PreviousData = (props) => {
    if (props.exerciseDisplay===null) {
      return(
        <div>
          <p className='no-data'>No Exercise Data Found</p>
        </div>
      )
      }
    else{
      return(
        <div className='prev-table'>
          <table className='prev-table-actual'>
            <thead>
              <tr>
                <th className='tableHeader'>Date</th>
                <th className='tableHeader'>Sets</th>
                <th className='tableHeader'></th>
                <th className='tableHeader'>Set1</th>
                <th className='tableHeader'>Set2</th>
                <th className='tableHeader'>Set3</th>
                <th className='tableHeader'>Set4</th>
                <th className='tableHeader'>Set5</th>
                <th className='tableHeader'>Set6</th>
              </tr>
            </thead>
            <tbody>
              {props.exerciseDisplay.map(x => {
                var exerciseDate = new Date(x.submissionTime)
                var month =  (1+exerciseDate.getMonth()).toString()
                var day = exerciseDate.getDate().toString()
                var year = exerciseDate.getFullYear().toString()
                var dateString = month+'/'+day+'/'+year
                return(
                  <React.Fragment>
                    <tr className='lightCellPrev'>
                      <td rowSpan = "2">{dateString}</td>
                      <td rowSpan = "2">{x.sets}</td>
                      <td >Reps</td>
                      <td>{x.repsSet1}</td>
                      <td>{x.repsSet2}</td>
                      <td>{x.repsSet3}</td>
                      <td>{x.repsSet4}</td>
                      <td>{x.repsSet5}</td>
                      <td>{x.repsSet6}</td>
                    </tr>
                    <tr className='darkCellPrev'>
                      <td>Weight</td>
                      <td>{x.weightSet1}</td>
                      <td>{x.weightSet2}</td>
                      <td>{x.weightSet3}</td>
                      <td>{x.weightSet4}</td>
                      <td>{x.weightSet5}</td>
                      <td>{x.weightSet6}</td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
      </div>
      )
    }
  }
  export default PreviousData