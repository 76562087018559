import React, {useState,useEffect} from 'react';
//<input  value={props.workoutName} onClick={()=>changeExercise(x.exercise, x.id)} onChange={(event) => handleExerciseChange(event,x.exercise,x.id)}></input>
const ExerciseTile = (props) => {
return(
    <div className='workout-set-tile'>
        <h1>{props.exercise}</h1>
        <input  value={props.exercise} onClick={()=>props.handleExerciseSelection(props.exercise, props.id)} onChange={(event) => props.handleExerciseChangeCallback(event,props.exercise,props.id)}></input>
    </div>
)
}

export default ExerciseTile